<template>
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.nameLocalized,
          })
        }}
      </p>
    </b-modal>
    <b-modal
      id="modal-edit-unit"
      :title="dto.id === null ? $t('COMMON.CREATE') : $t('COMMON.CHANGE')"
      @ok="saveUnit"
      @cancel="clearUnit"
      @close="clearUnit"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group
        id="label-en-name"
        label-cols-lg="5"
        :label="$t('UNIT.NAME_EN')"
        label-for="en-name-input"
      >
        <b-form-input id="en-name-input" v-model="dto.enName"></b-form-input>
      </b-form-group>
      <b-form-group
        id="label-ru-name"
        label-cols-lg="5"
        :label="$t('UNIT.NAME_RU')"
        label-for="ru-name-input"
      >
        <b-form-input id="ru-name-input" v-model="dto.ruName"></b-form-input>
      </b-form-group>
      <b-form-group
        id="label-es-name"
        label-cols-lg="5"
        :label="$t('UNIT.NAME_ES')"
        label-for="es-name-input"
      >
        <b-form-input id="es-name-input" v-model="dto.esName"></b-form-input>
      </b-form-group>
      <b-form-group
        id="label-code"
        label-cols-lg="5"
        :label="$t('UNIT.CODE')"
        label-for="code-input"
      >
        <b-form-input id="code-input" v-model="dto.code"></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";

export default {
  name: "units",
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "created",
          formatter: Common.idObjectFormatter,
          label: this.$t("COMMON.CREATED"),
          sortingKey: "el.created",
          sortable: true,
        },
        {
          key: "nameLocalized",
          label: this.$t("UNIT.NAME"),
        },
        {
          key: "code",
          label: this.$t("UNIT.CODE"),
          sortingKey: "el.code",
          sortable: true,
        },
      ],

      response: {
        data: [],
        totalCount: 1,
      },

      getList: {
        resource: "/api/unit",
        requestType: "GET",
        requestParams: {},
      },

      saveRequest: {
        resource: "/api/unit/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        enName: "",
        ruName: "",
        esName: "",
        code: "",
      },
      namePattern: 'i18n{"en": "envalue", "ru": "ruvalue", "es": "esvalue"}',

      calculate: false,

      currentPage: 1,
      perPage: 10,
      showPerPageArray: [10, 50, 100],

      selectedItem: null,
      filterItems: [],
      filterItem: "",

      showTableOverlay: true,
    };
  },
  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },
  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    this.loadList();
  },
  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.UNITS") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },
  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onCreateNewAction: function () {
      this.$bvModal.show("modal-edit-unit");
    },

    onEditAction: function () {
      this.dto.id = this.selectedItem.id;
      this.dto.code = this.selectedItem.code;

      // костыль для парсинга i18n
      this.dto.enName = this.selectedItem.name.substring(
        this.selectedItem.name.lastIndexOf('"en": "') + 7,
        this.selectedItem.name.indexOf('", "ru"')
      );
      this.dto.ruName = this.selectedItem.name.substring(
        this.selectedItem.name.lastIndexOf('"ru": "') + 7,
        this.selectedItem.name.indexOf('", "es"')
      );
      this.dto.esName = this.selectedItem.name.substring(
        this.selectedItem.name.lastIndexOf('"es": "') + 7,
        this.selectedItem.name.indexOf('"}')
      );

      this.$bvModal.show("modal-edit-unit");
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },
    onDelete: function () {
      let that = this;
      const del = {
        resource: "/api/unit/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },
    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;
      this.loadList();
    },
    saveUnit: function (modal) {
      if (this.dto.code.trim() === "") {
        this.$bvToast.toast(this.$t("UNIT.CODE_NOT_SET"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
        modal.preventDefault();
        return;
      }

      this.dto.name =
        'i18n{"en": "' +
        this.dto.enName.trim() +
        '", "ru": "' +
        this.dto.ruName.trim() +
        '", "es": "' +
        this.dto.esName.trim() +
        '"}';

      this.showTableOverlay = true;
      this.saveRequest.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, this.saveRequest)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
          this.loadList();
        })
        .catch((response) => {
          this.showTableOverlay = false;
          this.onError(response);
        });
    },
    clearUnit: function () {
      this.dto = {
        id: null,
        enName: "",
        ruName: "",
        esName: "",
        code: "",
      };
    },
  },
};
</script>

<style scoped></style>
